/* requries:
jquery-3.2.1.min.js
*/

$(document).ready(function(){

	/* GLOBALS */
	var _STATE_SCORES = { value: { } },
		_VULNERABILITY = { value: { } },
		_PREPAREDNESS = { value: { } };

	var _LEAST_VUL_MAX = 4.7,
		_MID_VUL_MAX = 5.3,
		_LEAST_PREP_MAX = 5.0,
		_MID_PREP_MAX = 5.8;

	var _CURRENT_V = { value: "off" },
		_CURRENT_P = { value: "off" }


	var smoothScroll = function() {
        $('body').on('click', 'a[href*="#"]', function(event) {
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
                && 
                location.hostname == this.hostname
            ) {
                this.focus();
                var target = $(this.hash);

                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top + 0
                    }, 1000, function() {});
                }
            }
        });
    }



	mobileNav = function() {
		$('#nav-icon1,#nav-icon2,#nav-icon3,#nav-icon4').click(function(){
			$(this).toggleClass('open');
			$('.menu').toggleClass('open');
		});
	}


	/* IMPPORT AND PARSE*/
	importData = function(){
		
		if ($('#state-data').length > 0){

			$.ajax({
				type: 'GET',
	  			url: 'assets/data/state-scores.csv',
	  			dataType: 'text',
	  			success: function(response){
	  				parseCSV(response, _STATE_SCORES);
	  			}
			}).done(vulnerabilityData);
			
		} else {
			$.ajax({
				type: 'GET',
	  			url: 'assets/data/state-scores.csv',
	  			dataType: 'text',
	  			success: function(response){
	  				parseCSV(response, _STATE_SCORES);
	  			}
			}).done(init);
		}	
	}

	vulnerabilityData = function() {
		$.ajax({
			type: 'GET',
  			url: 'assets/data/vulnerability.csv',
  			dataType: 'text',
  			success: function(response){
  				parseCSV(response, _VULNERABILITY);
  			}
		}).done(preparednessData);
	}

	preparednessData = function() {
		$.ajax({
			type: 'GET',
  			url: 'assets/data/preparedness.csv',
  			dataType: 'text',
  			success: function(response){
  				parseCSV(response, _PREPAREDNESS);
  			}
		}).done(init);
	}

	parseCSV = function(csv, variable) {
		var lines=csv.split("\n");
		var result = [];
		var headers=lines[0].split(",");

		for(var i=1;i<lines.length;i++){

		  var obj = {};
		  var currentline=lines[i].split(",");

		  for(var j=0;j<headers.length;j++){
		      obj[headers[j]] = currentline[j];
		  }
		  result.push(obj);
		}	

		variable.value = result;
	}


	/*STATE DISPLAY*/

	setStateData = function(){
		var index = $('#state-data').data('state-index'),
			headline_data = _STATE_SCORES.value[index],
			v_body_data = _VULNERABILITY.value[index],
			p_body_data = _PREPAREDNESS.value[index];
			state = _STATE_SCORES.value[index]['state'];
		
		populateStateHeader(state);

		populateVulnerabilityData(headline_data, v_body_data);
		populatePreparednessData(headline_data, p_body_data);
	}

	populateStateHeader = function(state) {
		var holder = $('body.state .state-data__state-name .header-holder');
		holder.find('.state-name').text(state);

		if( state == "District of Columbia") {
			holder.prepend('<img src="/assets/images/individual-states/district-of-columbia.svg" alt="' + state + '">')
		} else {
			holder.prepend('<img src="/assets/images/individual-states/' + state.replace(' ', '-').toLowerCase() + '.svg" alt="' + state + '">')
		}
	}

	showDefinitions = function () {
		$('body.state .expander').click (function (e) {
			$(this).next('.expandable').toggleClass('visible');
			$(this).toggleClass('visible');
		})
	}

	populateVulnerabilityData = function(headline_data, body_data) {
		var vulnerabilityLevel = "Low",
			score = headline_data['vulnerability_score'];

		var statusClass = "low";

		if( score > _MID_VUL_MAX ) {
			vulnerabilityLevel = "High";
			statusClass = "high";
		} else if ( score > _LEAST_VUL_MAX  && score <= _MID_VUL_MAX) {
			vulnerabilityLevel = "Medium";
			statusClass = "med";
		}

		$('.state-data__vulnerability .header').toggleClass(statusClass, true);
		$('#state-data__vulnerability--score').text(score);
		$('#state-data__vulnerability--rank').text(vulnerabilityLevel);

		$('#v1').text(body_data['Extreme Heat']);
		$('#v2').text(body_data['Flooding']);
		$('#v3').text(body_data['Drought']);
		$('#v4').text(body_data['Wildfire']);
		$('#v5').text(body_data['Sever Storms']);
		$('#v6').text(body_data['Disease Vectors']);

		$('#v7').text(body_data['Poverty']);
		$('#v8').text(body_data['Income Inequality']);
		$('#v9').text(body_data['Age Composition']);
		$('#v10').text(body_data['Nonwhite Population']);
		$('#v11').text(body_data['Disability']);
		$('#v12').text(body_data['Housing']);
		$('#v13').text(body_data['Transportation']);
		$('#v14').text(body_data['Language']);
		$('#v15').text(body_data['Education Level']);
	}

	populatePreparednessData = function(headline_data, body_data) {
		var preparednessLevel = "Low",
			score = headline_data['preparedness_score'];

		var statusClass = "low";

		if( score > _MID_PREP_MAX ) {
			preparednessLevel = "High";
			statusClass = "high";
		} else if ( score > _LEAST_PREP_MAX  && score <= _MID_PREP_MAX) {
			preparednessLevel = "Medium";
			statusClass = "med";
		}

		$('.state-data__preparedness .header').toggleClass(statusClass, true);
		$('#state-data__preparedness--score').text(score);
		$('#state-data__preparedness--rank').text(preparednessLevel);


		$('#p1').text(body_data['Health surveillance and epidemiological investigation']);
		$('#p2').text(body_data['Cross-sector / community collaboration']);
		$('#p3').text(body_data['Social capital and cohesion']);
		$('#p4').text(body_data['Incident management']);
		$('#p5').text(body_data['Information management']);
		$('#p6').text(body_data['Prehospital care']);
		$('#p7').text(body_data['Hospital and physician services']);
		$('#p8').text(body_data['Long-term care']);
		$('#p9').text(body_data['Mental and behavioral healthcare']);
		$('#p10').text(body_data['Home care']);
		$('#p11').text(body_data['Environmental monitoring']);
		
		if(body_data['D3.1.1'] == "X") {
			$('#p12').toggleClass('isTrue', true);
		}
		if(body_data['D3.1.2'] == "X") {
			$('#p13').toggleClass('isTrue', true);
		}
		if(body_data['D3.1.3'] == "X") {
			$('#p14').toggleClass('isTrue', true);
		}
		if(body_data['D3.1.4'] == "X") {
			$('#p15').toggleClass('isTrue', true);
		}
		if(body_data['D3.1.5'] == "X") {
			$('#p16').toggleClass('isTrue', true);
		}
		if(body_data['D3.1.6.1'] == "X") {
			$('#p17').toggleClass('isTrue', true);
		}

		if(body_data['D3.2.1'] == "X") {
			$('#p19').toggleClass('isTrue', true);
		}

		if(body_data['D3.2.2'] == "X") {
			$('#p20').toggleClass('isTrue', true);
		}
	}

	dropdownToggle = function(){
		$('#dropdown-arrow').on('click', function(){
			$(this).parent().toggleClass('open');
		});
	}

	/* MAP INTERACTIONS*/
	stateClick = function(){
		$('.state').each(function(){
			$(this).on('click', function(){
				$('#reset').trigger('click');
				var index = $(this).data('index');

				var prep = scoreRankPreparedness(_STATE_SCORES.value[index]['preparedness_score']),
					vuln = scoreRankVulnerability(_STATE_SCORES.value[index]['vulnerability_score']),
					stateId = $(this).data('index'),
				    url = vuln +'-'+ prep;

				var blurb = _STATE_SCORES.value[index]['blurb'].replace('**', ', ').replace('[', '').replace(']', '');
				blurb = blurb.replace("\"", " ");
				blurb = blurb.replace("\"", " ");

				$('.state').attr('fill','#fff');
				$('.state').attr('fill-opacity','0');
				$('*[data-index="'+stateId+'"]').attr('fill','url(#'+url+')');
				$('*[data-index="'+stateId+'"]').attr('fill-opacity','1');
				$('#v_score').text(_STATE_SCORES.value[index]['vulnerability_score']);
				$('#p_score').text(_STATE_SCORES.value[index]['preparedness_score']);
				$('#name-display').text(_STATE_SCORES.value[index]['state']);
				$('#state-page').attr('href',_STATE_SCORES.value[index]['page_url']);
				$('.state-page__btn-wrapper').toggleClass('inactive', false);
				$('.state-page__btn-wrapper a').removeAttr("onclick", "").unbind("click");
				$('#instructions').text(blurb);

				//Table Colors
				$('#v-headline').attr('data-vcolor',_STATE_SCORES.value[index]['v_rank']);
				$('#p-headline').attr('data-pcolor',_STATE_SCORES.value[index]['p_rank']);
				$('#v_score').attr('data-vcolor',_STATE_SCORES.value[index]['v_rank']);
				$('#p_score').attr('data-pcolor',_STATE_SCORES.value[index]['p_rank']);
				$('#v_info').attr('data-vcolor',_STATE_SCORES.value[index]['v_rank']);
				$('#p_info').attr('data-pcolor',_STATE_SCORES.value[index]['p_rank']);

				$('#carousel').slick('slickGoTo', index);
				updateMobileTable(index)
			});
		});
	}

	scoreRankPreparedness = function(score) {
		var scoreRank = 'low';
		if( score >= _MID_PREP_MAX ) {
			scoreRank = "high";
			return scoreRank;
		} else if ( score > _LEAST_PREP_MAX  && score <= _MID_PREP_MAX) {
			scoreRank = "med";
			return scoreRank;
		}
		return scoreRank;
	}

	scoreRankVulnerability = function(score) {
		var scoreRank = 'low';
		if( score >= _MID_VUL_MAX ) {
			scoreRank = "high";
		} else if ( score > _LEAST_VUL_MAX  && score <= _MID_VUL_MAX) {
			scoreRank = "med";
		}
		return scoreRank;
	}

	disclaimerBox = function() {
		$('#disclaimer-box').on('click', function(){
			$('#reset').trigger('click');
			var blurb = "American Indian and Alaska Native tribal nations and U.S. territories were not included in the assessment, owing to a lack of comparable data, a serious gap that this country must work to fill, given the acute threat that climate change poses to many of their residents.";
			$('#v_score').text("--");
			$('#p_score').text("--");
			$('#name-display').text('TRIBAL NATIONS AND U.S. TERRITORIES');
			$('.state-page__btn-wrapper').toggleClass('inactive', true);
			$('#instructions').text(blurb);
		});
	}

	mapSliders = function() {

		$( "#slider-v" ).slider({
			slide: function( event, ui ) {
				var current = ui.value;	
				$('.map-button').toggleClass('active', false);

				if(_CURRENT_P.value == "all") {
					_CURRENT_P.value = "off"
				}

				if ( ui.value <= 10 ) {
					_CURRENT_V.value = "off";
					recalibrateMap();
				} else if( ui.value <= 40 ) {
					_CURRENT_V.value = "low";
					recalibrateMap();
				} else if( ui.value <= 70 ) {
					_CURRENT_V.value = "med";
					recalibrateMap();
				} else {
					_CURRENT_V.value = "high";
					recalibrateMap();
				}
           	},
		    stop: function( event, ui ) {
				mobileStateFilter();

		        if ( ui.value <= 10 ) {
					$( "#slider-v" ).slider( "value", 5 );
				} else if( ui.value <= 40 ) {
					$( "#slider-v" ).slider( "value", 24.5 );
				} else if( ui.value <= 70 ) {
					$( "#slider-v" ).slider( "value", 55 );
				} else {
					$( "#slider-v" ).slider( "value", 85 );
				} 		        
		    }
        });


		$( "#slider-p" ).slider({
			slide: function( event, ui ) {
            	var current = ui.value;
				$('.map-button').toggleClass('active', false);

				if(_CURRENT_V.value == "all") {
					_CURRENT_V.value = "off"
				}

				if( ui.value <= 30 ) {
					_CURRENT_P.value = "low";
					recalibrateMap();
				} else if( ui.value <= 60 ) {
					_CURRENT_P.value = "med";
					recalibrateMap();
				} else if( ui.value <= 90 ) {
					_CURRENT_P.value = "high";
					recalibrateMap();
				} else {
					_CURRENT_P.value = "off";
					recalibrateMap();
				}
            },
		    stop: function( event, ui ) {
		    	mobileStateFilter();

		        if( ui.value <= 30 ) {
					$( "#slider-p" ).slider( "value", 15 );
				} else if( ui.value <= 60 ) {
					$( "#slider-p" ).slider( "value", 45 );
				} else if( ui.value <= 90 ) {
					$( "#slider-p" ).slider( "value", 75 );
				} else {
					$( "#slider-p" ).slider( "value", 95 );
				}
			}
		});
	}

	showAll = function(){
		$('#vuln-all').on('click', function(){
			$('#reset').trigger('click');
			_CURRENT_V.value = "all";
			_CURRENT_P.value = "off";
			$( "#slider-v" ).slider( "value", 0 );
			$( "#slider-p" ).slider( "value", 100 );
			$('.map-button').toggleClass('active', false);
			$('.state-page__btn-wrapper').toggleClass('inactive', true);
			$('.state-page__btn-wrapper a').on('click', function(){
				return false;
			})
			$(this).toggleClass('active', true);
			recalibrateMap();
			clearTableDesktop();
		});

		$('#prep-all').on('click', function(){
			$('#reset').trigger('click');
			_CURRENT_P.value = "all";
			_CURRENT_V.value = "off";
			$( "#slider-v" ).slider( "value", 0 );
			$( "#slider-p" ).slider( "value", 100 );
			$('.map-button').toggleClass('active', false);
			$('.state-page__btn-wrapper').toggleClass('inactive', true);
			$('.state-page__btn-wrapper a').on('click', function(){
				return false;
			})
			$(this).toggleClass('active', true);
			recalibrateMap();
			clearTableDesktop();
		});
	}

	recalibrateMap = function() {
		if(_CURRENT_V.value == "off" || _CURRENT_P.value == "off"){
			if ( _CURRENT_V.value == "off" ) {
				//DONE
				for (var i = _STATE_SCORES.value.length - 1; i >= 0; i--) {
					if(_CURRENT_P.value == "all") {
						var url = _STATE_SCORES.value[i]['p_rank'];
						$('*[data-index="'+i+'"]').attr('fill','url(#p-'+url+')');
						$('*[data-index="'+i+'"]').attr('fill-opacity','1');
					} else if( _STATE_SCORES.value[i]['p_rank'] == _CURRENT_P.value ) {
						var url = _STATE_SCORES.value[i]['p_rank'];
						$('*[data-index="'+i+'"]').attr('fill','url(#p-'+url+')');
						$('*[data-index="'+i+'"]').attr('fill-opacity','1');
					} else {
						$('*[data-index="'+i+'"]').attr('fill-opacity','0');
					}
				}
			} else {
				//DONE
				for (var i = _STATE_SCORES.value.length - 1; i >= 0; i--) {
					if(_CURRENT_V.value == "all") {
						var url = _STATE_SCORES.value[i]['v_rank'];
						$('*[data-index="'+i+'"]').attr('fill','url(#v-'+url+')');
						$('*[data-index="'+i+'"]').attr('fill-opacity','1');
					}else if( _STATE_SCORES.value[i]['v_rank'] == _CURRENT_V.value ) {
						var url = _STATE_SCORES.value[i]['v_rank'];
						$('*[data-index="'+i+'"]').attr('fill','url(#v-'+url+')');
						$('*[data-index="'+i+'"]').attr('fill-opacity','1');
					} else {
						$('*[data-index="'+i+'"]').attr('fill-opacity','0');
					}
				}
			}
		} else if(_CURRENT_V.value == "all"  || _CURRENT_P.value == "all" ) {
			if( _CURRENT_V.value == "all" && _CURRENT_P.value != "all") {
				for (var i = _STATE_SCORES.value.length - 1; i >= 0; i--) {
					if( _STATE_SCORES.value[i]['p_rank'] == _CURRENT_P.value ) {
						var url = _STATE_SCORES.value[i]['v_rank'] + "-" +_STATE_SCORES.value[i]['p_rank'];
						$('*[data-index="'+i+'"]').attr('fill','url(#'+url+')');
						$('*[data-index="'+i+'"]').attr('fill-opacity','1');
					} else {
						var url = _STATE_SCORES.value[i]['v_rank'];
						$('*[data-index="'+i+'"]').attr('fill','url(#v-'+url+')');
						$('*[data-index="'+i+'"]').attr('fill-opacity','1');
					}
				}
			}
			if( _CURRENT_V.value != "all" && _CURRENT_P.value == "all") {
				//DONE
				for (var i = _STATE_SCORES.value.length - 1; i >= 0; i--) {
					if( _STATE_SCORES.value[i]['v_rank'] == _CURRENT_V.value ) {
						var url = _STATE_SCORES.value[i]['v_rank'] + "-" +_STATE_SCORES.value[i]['p_rank'];
						$('*[data-index="'+i+'"]').attr('fill','url(#'+url+')');
						$('*[data-index="'+i+'"]').attr('fill-opacity','1');
					} else {
						var url = _STATE_SCORES.value[i]['p_rank'];
						$('*[data-index="'+i+'"]').attr('fill','url(#p-'+url+')');
						$('*[data-index="'+i+'"]').attr('fill-opacity','1');
					}
				}
			}
			if( _CURRENT_V.value == "all" && _CURRENT_P.value == "all") {
				//DONE
				for (var i = _STATE_SCORES.value.length - 1; i >= 0; i--) {
					var url = _STATE_SCORES.value[i]['v_rank'] + '-' + _STATE_SCORES.value[i]['p_rank'];
					$('*[data-index="'+i+'"]').attr('fill','url(#'+url+')');
					$('*[data-index="'+i+'"]').attr('fill-opacity','1');
				}
			}
			return;
		} else {
			var url = _CURRENT_V.value +'-'+ _CURRENT_P.value;
			for (var i = _STATE_SCORES.value.length - 1; i >= 0; i--) {
				if( _STATE_SCORES.value[i]['v_rank'] == _CURRENT_V.value && _STATE_SCORES.value[i]['p_rank'] == _CURRENT_P.value ) {
					$('*[data-index="'+i+'"]').attr('fill','url(#'+url+')');
					$('*[data-index="'+i+'"]').attr('fill-opacity','1');
				} else {
					$('*[data-index="'+i+'"]').attr('fill-opacity','0');
				}
			}
		}

	}

	resetAll = function() {
		$('#reset').on('click', function(){
			_CURRENT_P.value = "off";
			_CURRENT_V.value = "off";
			$('.map-button').toggleClass('active', false);
			$('.state').attr('fill-opacity','0');   

			$('#slider-v').slider("value", 0);
			$('#slider-p').slider("value", 100);
			$('.state-page__btn-wrapper').toggleClass('inactive', true);
			$('.state-page__btn-wrapper a').on('click', function(){
				return false;
			})
			clearTableDesktop();
		});
	}

	mobileStateCarousel = function() {
		$('#carousel').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			centerMode: true,
			arrows: true,
		});

		$('#carousel').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		    updateMobileTable(nextSlide);
		   // $('#state-'+nextSlide).trigger('click');

		   //	console.log(nextSlide);

		   	// FOR SOME REASON THIS ISNT WORKING IF YOU CLICK STATE THEN SLIDER THEN STATE
		/*	var index = nextSlide,
				prep = scoreRankPreparedness(_STATE_SCORES.value[index]['preparedness_score']),
				vuln = scoreRankVulnerability(_STATE_SCORES.value[index]['vulnerability_score']),
				stateId = nextSlide,
			    url = vuln +'-'+ prep;

			var blurb = _STATE_SCORES.value[index]['blurb'].replace('**', ', ');
			blurb = blurb.replace("\"", " ");
			blurb = blurb.replace("\"", " ");

			$('.state').attr('fill','#fff');
			$('.state').attr('fill-opacity','0');
			$('*[data-index="'+index+'"]').attr('fill','url(#'+url+')');
			$('*[data-index="'+index+'"]').attr('fill-opacity','1');*/
		});
	}

	mobileStateFilter = function() {
		var classes = "";
		//_CURRENT_V
		if(_CURRENT_V.value != "off") {
			classes = ".v-" + _CURRENT_V.value;
		}
		if(_CURRENT_P.value != "off") {
			classes = classes + ".p-" + _CURRENT_P.value;
		}

		if ( classes != "" ) {
			// The filter function shows only the ones with the corresponding class
			$('#carousel').slick('slickUnfilter');
			$('#carousel').slick('slickFilter', classes);
		} else {
			$('#carousel').slick('slickUnfilter');
		}
	}

	var tabToggle = function(){
		$('.tab').each(function(){
			$(this).on('click', function(){
				if( $(this).hasClass('open')) {
					$('.tab').toggleClass('open', false);
					$(this).parent().toggleClass('active', false);
				} else {
					$('.tab.open').toggleClass('open', false);
					$('.action-items__table-tabs').toggleClass('active', false);
					$(this).toggleClass('open', true);
					$(this).parent().toggleClass('active', true);

					var htmlContent = $(this).children('.details').html();

					// Update overlay data content;
					if( $(this).hasClass('table-1') ) {
						$('#table-1__overlay').html(htmlContent);
					} else {
						$('#table-2__overlay').html(htmlContent);
					}
				}
			});
		});
	}

	var infoToggle = function(){
		$('.info-box').each(function(){
			$(this).on('click', function(){
				if( $(this).hasClass('open')) {
					$('.info-box').toggleClass('open', false);
				} else {
					$('.info-box').toggleClass('open', false);
					$(this).toggleClass('open', true);
				}
			});
		});
	}

	var clearTableDesktop = function(){
		$('.info-colors').attr('data-vcolor','');
		$('.info-colors').attr('data-pcolor','');
		$('#v_score').text('0.0');
		$('#p_score').text('0.0');
		$('#name-display').text('State Information');
		$('#instructions').text('Choose your state to learn more about how their levels of vulnerability and preparedness.');
	}

	var mobileStateControls = function(){
		$('#state-placeholder').on('click', function(){
			$('.dropdown-container').toggleClass('open');
		});

		$('#state-dropdown-list li').each(function(){
			$(this).on('click', function(){
				var index = $(this).data('index');

				$('.dropdown-container').toggleClass('open');

				// trigger slide change
				$('#carousel').slick('slickGoTo', index);
				updateMobileTable(index);
			});
		});
	}

	var updateMobileTable = function(index){
		
		$('.state-page__btn-wrapper').toggleClass('inactive', false);
		$('.state-page__btn-wrapper a').removeAttr("onclick", "").unbind("click");
		// Update text data
		var blurb = _STATE_SCORES.value[index]['blurb'].replace('**', ', ').replace('[', '').replace(']', '');
			blurb = blurb.replace("\"", " ");
			blurb = blurb.replace("\"", " ");

		$('#name-display').text(_STATE_SCORES.value[index]['state']);
		$('#state-page').attr('href',_STATE_SCORES.value[index]['page_url']);
		$('#instructions').text(blurb);

		// Update ranking blocks
		var newVClass = "v-"+_STATE_SCORES.value[index]['v_rank'],
			vClasses  = "mobile-details__rank " + newVClass,
		    newPClass = "p-"+_STATE_SCORES.value[index]['p_rank'],
		    pClasses  = "mobile-details__rank " + newPClass;

		if(_STATE_SCORES.value[index]['v_rank'] == 'med') {
			$('#mobile-v-details span').text("Medium");
			$('#mobile-v-details').removeClass();
			$('#mobile-v-details').toggleClass(vClasses);
		} else {
			$('#mobile-v-details span').text(_STATE_SCORES.value[index]['v_rank']);
			$('#mobile-v-details').removeClass();
			$('#mobile-v-details').toggleClass(vClasses);
		}

		if(_STATE_SCORES.value[index]['p_rank'] == 'med') {
			$('#mobile-p-details span').text("Medium");
			$('#mobile-p-details').removeClass();
			$('#mobile-p-details').toggleClass(pClasses);
		} else {
			$('#mobile-p-details span').text(_STATE_SCORES.value[index]['p_rank']);
			$('#mobile-p-details').removeClass();
			$('#mobile-p-details').toggleClass(pClasses);
		}
	}


	init = function () {
		if ($('#state-data').length > 0){
			setStateData();
			showDefinitions();
			dropdownToggle();
		} else {
			stateClick();
			mapSliders();
			resetAll();
			mobileStateCarousel(); 
			tabToggle();
			infoToggle();
			mobileStateControls();
			showAll();
			disclaimerBox();
			
			$( "#slider-v" ).slider( "value", 5 );
			$( "#slider-p" ).slider( "value", 95 );
		}

		if( $(window).width() < 900 ) {
			updateMobileTable(0);
		}

		smoothScroll();
		mobileNav();
	}

	importData();
});